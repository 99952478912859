import React from 'react';
import '../styles/Icon.css'

const preventDragHandler = (e) => {
  e.preventDefault();
};

export function GitHubIcon({ size = 70, includeLink = true }) {
  if (includeLink) {
    return (
      <a href="https://github.com/spookypannkaka" target="_blank" rel="noopener noreferrer" onDragStart={preventDragHandler}>
        <svg className="bannericon" style={{ width: `${size}px`, height: `${size}px` }} viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" transform="scale(64)"/>
        </svg>
      </a>
    );   
  } else {
    return (
      <svg className="videoicon" style={{ width: `${size}px`, height: `${size}px` }} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" transform="scale(64)"/>
      </svg>
    );    
  }
}
  
export function LinkedInIcon({ size = 70 }) {
  return (
    <a href="https://www.linkedin.com/in/lovisasve/" target="_blank" rel="noopener noreferrer" onDragStart={preventDragHandler}>
      <svg className="bannericon" style={{ width: `${size}px`, height: `${size}px` }} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="m17.0391667 17.0433333h-2.9616667v-4.6408333c0-1.1066667-.0225-2.53083333-1.5433333-2.53083333-1.5441667 0-1.78 1.20416663-1.78 2.44916663v4.7225h-2.9616667v-9.5433333h2.845v1.30083333h.0383333c.3975-.75 1.3641667-1.54166666 2.8083334-1.54166666 3.0008333 0 3.5558333 1.975 3.5558333 4.54583333v5.2383333zm-12.5916667-10.84916663c-.95333333 0-1.71916667-.77166667-1.71916667-1.72083334 0-.94833333.76666667-1.71916666 1.71916667-1.71916666.95 0 1.72.77083333 1.72 1.71916666 0 .94916667-.77083333 1.72083334-1.72 1.72083334zm1.485 10.84916663h-2.97v-9.5433333h2.97zm12.5883333-17.0433333h-17.04499997c-.81583333 0-1.47583333.645-1.47583333 1.44083333v17.11833337c0 .7966666.66 1.4408333 1.47583333 1.4408333h17.04249997c.815 0 1.4816667-.6441667 1.4816667-1.4408333v-17.11833337c0-.79583333-.6666667-1.44083333-1.4816667-1.44083333z" /*fill="none"*//></svg>
    </a>
  );
}
  
export function CVIcon({ size = 70 }) {
  return (
    <a href="/CV_LovisaSve.pdf" target="_blank" rel="noopener noreferrer" onDragStart={preventDragHandler}>
      <svg className="bannericon" style={{ width: `${size}px`, height: `${size}px` }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M3 24h19v-23h-1v22h-18v1zm17-24h-18v22h18v-22zm-1 1h-16v20h16v-20zm-2 16h-12v1h12v-1zm0-3h-12v1h12v-1zm0-3h-12v1h12v-1zm-7.348-3.863l.948.3c-.145.529-.387.922-.725 1.178-.338.257-.767.385-1.287.385-.643 0-1.171-.22-1.585-.659-.414-.439-.621-1.04-.621-1.802 0-.806.208-1.432.624-1.878.416-.446.963-.669 1.642-.669.592 0 1.073.175 1.443.525.221.207.386.505.496.892l-.968.231c-.057-.251-.177-.449-.358-.594-.182-.146-.403-.218-.663-.218-.359 0-.65.129-.874.386-.223.258-.335.675-.335 1.252 0 .613.11 1.049.331 1.308.22.26.506.39.858.39.26 0 .484-.082.671-.248.187-.165.322-.425.403-.779zm3.023 1.78l-1.731-4.842h1.06l1.226 3.584 1.186-3.584h1.037l-1.734 4.842h-1.044z"/></svg>
    </a>
  );
}
  
export function EmailIcon({ size = 70 }) {
  return (
  <a href="mailto:amloma42@gmail.com" onDragStart={preventDragHandler}>
    <svg className="bannericon" style={{ width: `${size}px`, height: `${size}px` }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 493.497 493.497">
      <path d="M444.556,85.218H48.942C21.954,85.218,0,107.171,0,134.16v225.177c0,26.988,21.954,48.942,48.942,48.942h395.613
        c26.988,0,48.941-21.954,48.941-48.942V134.16C493.497,107.171,471.544,85.218,444.556,85.218z M460.87,134.16v225.177
        c0,2.574-0.725,4.924-1.793,7.09L343.74,251.081l117.097-117.097C460.837,134.049,460.87,134.096,460.87,134.16z M32.628,359.336
        V134.16c0-0.064,0.033-0.11,0.033-0.175l117.097,117.097L34.413,366.426C33.353,364.26,32.628,361.911,32.628,359.336z
        M251.784,296.902c-2.692,2.691-7.378,2.691-10.07,0L62.667,117.846h368.172L251.784,296.902z M172.827,274.152l45.818,45.819
        c7.512,7.511,17.493,11.645,28.104,11.645c10.61,0,20.592-4.134,28.104-11.645l45.82-45.819l101.49,101.499H71.327L172.827,274.152z
        "/>
    </svg>
  </a>
  );
}

export function DurationIcon() {
  return (
      <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0 11h6v1h-7v-9h1v8z" onDragStart={preventDragHandler}/></svg>
  );
}

export function PeopleIcon() {
    return (
<svg
   viewBox="0 0 100 100"
   x="0px"
   y="0px"
   version="1.1"
   className="icon"
   onDragStart={preventDragHandler}>
  <g
     id="g148"
     transform="matrix(1.6480449,0,0,1.6480449,-2.7779358,-3.690144)">
    <path
       d="M 23.71,21.59 A 8.29,8.29 0 1 0 32,13.3 8.3,8.3 0 0 0 23.71,21.59 Z m 14.58,0 A 6.29,6.29 0 1 1 32,15.3 a 6.29,6.29 0 0 1 6.29,6.29 z"
       id="path64"/>
    <path
       d="m 48.92,30.73 a 12,12 0 0 0 -7.85,2.92 15.21,15.21 0 0 0 -18.14,0 A 12.06,12.06 0 0 0 3,42.81 v 2.92 a 1,1 0 0 0 1,1 h 12.71 v 3 a 1,1 0 0 0 1,1 h 28.58 a 1,1 0 0 0 1,-1 v -3 H 60 a 1,1 0 0 0 1,-1 V 42.81 A 12.09,12.09 0 0 0 48.92,30.73 Z M 5,42.81 a 10.08,10.08 0 0 1 16.38,-7.87 l -0.2,0.22 -0.39,0.41 c -0.22,0.24 -0.44,0.49 -0.65,0.74 l -0.35,0.45 c -0.21,0.27 -0.4,0.55 -0.58,0.83 -0.18,0.28 -0.2,0.29 -0.29,0.44 -0.2,0.34 -0.4,0.7 -0.58,1.06 0,0.09 -0.1,0.18 -0.14,0.27 -0.22,0.46 -0.41,0.93 -0.59,1.41 0,0.12 -0.07,0.24 -0.11,0.36 -0.12,0.36 -0.23,0.73 -0.32,1.1 -0.05,0.17 -0.08,0.35 -0.12,0.52 -0.07,0.33 -0.13,0.66 -0.17,1 -0.04,0.34 -0.05,0.38 -0.07,0.57 -0.02,0.19 -0.05,0.28 -0.06,0.42 H 5 Z m 13.71,5.89 v -2.82 a 0.66,0.66 0 0 0 0,-0.14 13.28,13.28 0 0 1 21.75,-10 c 0.32,0.28 0.63,0.56 0.92,0.85 a 13.18,13.18 0 0 1 3.89,9.2 c 0,0 0,0.09 0,0.11 v 2.8 z M 59,44.7 H 47.24 c 0,-0.1 0,-0.19 0,-0.29 0,-0.1 -0.05,-0.41 -0.07,-0.62 C 47.15,43.58 47.05,43.3 47,43 46.95,42.7 46.92,42.59 46.87,42.39 46.82,42.19 46.74,41.88 46.67,41.62 L 46.49,41.03 C 46.4,40.78 46.31,40.54 46.21,40.29 46.11,40.04 46.06,39.91 45.98,39.73 45.9,39.55 45.75,39.24 45.63,39 c -0.12,-0.24 -0.17,-0.36 -0.27,-0.53 -0.1,-0.17 -0.29,-0.48 -0.44,-0.72 -0.15,-0.24 -0.19,-0.32 -0.3,-0.47 -0.11,-0.15 -0.37,-0.51 -0.57,-0.76 -0.2,-0.25 -0.18,-0.25 -0.28,-0.37 -0.3,-0.36 -0.62,-0.72 -1,-1.06 L 42.62,35 A 10.08,10.08 0 0 1 59,42.81 Z"
       id="path66"/>
    <path
       d="m 48.92,16.85 a 6.66,6.66 0 1 0 6.65,6.65 6.65,6.65 0 0 0 -6.65,-6.65 z m 0,11.31 a 4.66,4.66 0 1 1 4.65,-4.66 4.66,4.66 0 0 1 -4.65,4.66 z"
       id="path68"/>
    <path
       d="m 8.43,23.5 a 6.65,6.65 0 1 0 6.65,-6.65 6.66,6.66 0 0 0 -6.65,6.65 z m 11.3,0 a 4.65,4.65 0 1 1 -4.65,-4.65 4.66,4.66 0 0 1 4.65,4.65 z"
       id="path70"/>
  </g>
</svg>
    );
}

export function ToolsIcon() {
    return (
<svg className="icon"
	 viewBox="0 0 489.347 489.347" 
   onDragStart={preventDragHandler}>
<g>
	<path d="M412.642,345.939l-59.523-14.229l-66.352-66.352l51.12-51.055c11.874,4.167,24.216,6.203,36.499,6.202
		c28.736-0.002,57.122-11.149,78.233-32.221c32.686-32.626,41.544-82.646,22.043-124.466l-9.042-19.391l-53.807,53.682
		l-24.986-24.941l53.822-53.696L421.17,10.42C379.3-9.036,329.218-0.195,296.546,32.417
		c-30.131,30.078-40.012,74.943-26.092,114.534l-20.111,20.086L102.13,18.837C91.061,7.731,76.32,1.605,60.621,1.587
		c-0.023,0-0.044,0-0.067,0c-15.696,0-30.45,6.104-41.553,17.195C7.886,29.897,1.77,44.669,1.778,60.378
		c0.008,15.697,6.129,30.456,17.233,41.553L167.18,250.094l-20.155,20.129c-39.652-13.917-84.597-4.061-114.733,26.02
		C-0.393,328.869-9.252,378.888,10.25,420.708l9.042,19.391l53.806-53.681l24.986,24.94l-53.822,53.697l19.48,9.051
		c14.814,6.883,30.652,10.224,46.388,10.224c28.738-0.001,57.124-11.148,78.235-32.221c30.132-30.078,40.013-74.943,26.093-114.534
		l51.082-51.018l66.366,66.366l14.229,59.523l76.705,76.706l66.507-66.507L412.642,345.939z M301.691,144.194
		c-14.181-30.419-7.73-66.807,16.05-90.545c18.28-18.246,44.036-26.278,68.827-22.6l-42.211,42.113l67.451,67.328l42.24-42.142
		c3.697,24.738-4.343,50.456-22.622,68.702c-23.802,23.759-60.288,30.197-90.793,16.02l-9.505-4.417l-34.603,34.559l-24.968-24.965
		l34.573-34.529L301.691,144.194z M31.778,60.362c-0.004-7.69,2.992-14.923,8.43-20.362c5.433-5.426,12.657-8.414,20.347-8.414
		c7.711,0.009,14.918,3.002,20.345,8.446l194.398,194.38l-40.711,40.659L40.221,80.714C34.781,75.277,31.782,68.049,31.778,60.362z
		 M167.171,430.877c-18.28,18.246-44.038,26.278-68.827,22.6l42.211-42.112l-67.451-67.329l-42.24,42.142
		c-3.698-24.737,4.343-50.455,22.623-68.702c23.801-23.758,60.288-30.197,90.792-16.021l9.505,4.417l34.609-34.565l24.967,24.966
		l-34.578,34.534l4.44,9.525C197.403,370.751,190.952,407.138,167.171,430.877z M373.342,397.227l-7.564-31.645l31.646,7.564
		l49.498,49.499l-24.081,24.081L373.342,397.227z"/>
</g>
</svg>
    );
}

export function ArrowIcon() {
    return (
        <svg className="arrow-icon" height="20" width="20" viewBox="0 0 330 330" onDragStart={preventDragHandler}>
            <path d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
                c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
                C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
                C255,161.018,253.42,157.202,250.606,154.389z"/>
        </svg>        
    );
}

export function StarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 245" width="30" height="30">
      <path d="m56,237 74-228 74,228L10,96h240" fill="#332B48"/>
    </svg>    
  );
}